import {
  DICTIONARY_CATEGORY,
  dictionaryActionItems,
  dictionaryColumns,
  dictionaryOptionsDataType,
  dictionaryTabsItems
} from './DictionaryTable.helpers'
import {
  StyledDictionaryButton,
  StyledDictionaryCell,
  StyledDictionaryCol,
  StyledDictionaryDropdown,
  StyledDictionaryIcon,
  StyledDictionaryRow,
  StyledDictionarySeachContainer,
  StyledDictionaryTabs
} from './DictionaryTable.styled'
import Icon from '@ant-design/icons'
import { Button, MenuProps, Switch, TablePaginationConfig } from 'antd'
import classNames from 'classnames'
import { DropdownCheckbox } from 'components/atoms/dropdown'
import { InputSearch } from 'components/atoms/input'
import { TableColumnSort } from 'components/atoms/table'
import {
  TableScroll,
  TableScrollProps
} from 'components/atoms/table/tableScroll'
import { CaretDown, Kebab, Plus } from 'icons'
import { FieldListQuery } from 'interfaces/field'
import { useCallback, useEffect, useMemo, useState } from 'react'

export type DictionaryTableState = Omit<FieldListQuery, 'page' | 'per_page'> & {
  dictionary_type: string
  pagination: TablePaginationConfig
}
export const defaultDictionaryTableState: DictionaryTableState = {
  dictionary_type: DICTIONARY_CATEGORY.EVENT,
  search: '',
  sort_by: 'name',
  sort_type: 'ASC',
  pagination: {
    total: 0,
    current: 1,
    pageSize: 20,
    position: []
  }
}
export type DictionaryTableProps = Pick<
  TableScrollProps,
  'loading' | 'dataSource'
> & {
  defaultValue?: DictionaryTableState
  value?: DictionaryTableState
  onChange?: (value: DictionaryTableState) => void
  onClickAction?: (menuInfo: string, record: any) => void
  onClickAdd?: MenuProps['onClick']
}
export const DictionaryTable = (props: DictionaryTableProps) => {
  const {
    loading,
    dataSource,
    defaultValue,
    value: valueProps,
    onChange,
    onClickAdd,
    onClickAction
  } = props
  const [value, setValue] = useState<DictionaryTableState>(
    defaultValue || valueProps || defaultDictionaryTableState
  )

  const columns = useMemo(() => {
    return dictionaryColumns?.[value.dictionary_type].map((col) => {
      if (col.dataIndex === 'action') {
        const items = dictionaryActionItems[value.dictionary_type]
        const onClick = (record: any) => {
          return ((menuInfo) => {
            onClickAction?.(menuInfo.key, record)
          }) as MenuProps['onClick']
        }
        return {
          ...col,
          render(_: any, record: any) {
            return (
              <StyledDictionaryDropdown
                menu={{ items, onClick: onClick(record) }}
                placement="bottomRight"
                trigger={['click']}
                overlayClassName="dropdown_overlay_action"
                getPopupContainer={(html) => html}>
                <StyledDictionaryButton
                  type="text"
                  icon={<Kebab />}
                  className="action"
                />
              </StyledDictionaryDropdown>
            )
          }
        }
      }

      if (col.dataIndex === 'status') {
        return {
          ...col,
          render(statusStr: string, record: any) {
            const status = Boolean(statusStr === 'enable')
            return (
              <Switch
                checked={status}
                size="small"
                onChange={() => onClickAction?.('status', record)}
              />
            )
          }
        }
      }

      return col
    })
  }, [onClickAction, value.dictionary_type])

  const handleChangeTable = useCallback(
    (pagination: TableScrollProps['pagination']) => {
      const newValue = {
        ...value,
        pagination: { ...value.pagination, ...pagination }
      }
      setValue(newValue)
      onChange?.(newValue)
    },
    [onChange, value]
  )

  const handleChange = useCallback(
    (key: keyof DictionaryTableState) => {
      return (val: any) => {
        const newValue = { ...value }
        newValue[key] = val

        if (key === 'sort_type') {
          if (val !== value.sort_type) {
            newValue.sort_by = 'name'
          }

          if (val === value.sort_type) {
            newValue.sort_by = 'name'
            newValue.sort_type = 'ASC'
          }
        }

        if (key === 'dictionary_type') {
          newValue.pagination.current = 1
          delete newValue.filter_data_type
          // newValue.sort_by = 'name'
          // newValue.sort_type = 'ASC'
        }

        setValue(newValue)
        onChange?.(newValue)
      }
    },
    [onChange, value]
  )

  useEffect(() => {
    const newValue = {
      ...defaultDictionaryTableState,
      ...valueProps,
      pagination: {
        ...defaultDictionaryTableState.pagination,
        ...valueProps?.pagination
      }
    }
    setValue(newValue)
  }, [valueProps])

  return (
    <TableScroll
      loading={loading}
      columns={columns}
      dataSource={dataSource}
      rowKey={(item, index) => `${item?.id}-${item.name}-${index}`}
      onChange={handleChangeTable}
      pagination={value.pagination}
      components={{
        header: {
          cell(props: any) {
            const { children, ...restProps } = props
            if (restProps?.className?.includes('name')) {
              const sortTypeName =
                value.sort_by === 'name' ? value.sort_type : undefined
              return (
                <th {...restProps}>
                  <StyledDictionaryCell className="name_cell">
                    {children}
                    <TableColumnSort
                      value={sortTypeName as 'ASC' | 'DESC' | undefined}
                      onChange={handleChange('sort_type')}
                      className="filter"
                    />
                  </StyledDictionaryCell>
                </th>
              )
            }
            if (restProps?.className?.includes('data_type')) {
              const options =
                dictionaryOptionsDataType?.[value.dictionary_type] || []
              const active = Boolean(value.filter_data_type?.length)
              return (
                <th {...restProps}>
                  <StyledDictionaryCell className="data_type_cell">
                    {children}
                    <DropdownCheckbox
                      title="FILTER DATA TYPE"
                      description="Select data type to filter"
                      options={options}
                      value={value.filter_data_type}
                      onOk={handleChange('filter_data_type')}>
                      <StyledDictionaryIcon
                        className={classNames('filter', { active })}>
                        <CaretDown />
                      </StyledDictionaryIcon>
                    </DropdownCheckbox>
                  </StyledDictionaryCell>
                </th>
              )
            }
            if (restProps?.className?.includes('status')) {
              const active = Boolean(value.filter_status?.length)
              return (
                <th {...restProps}>
                  <StyledDictionaryCell className="status_cell">
                    {children}
                    <DropdownCheckbox
                      title="FILTER STATUS"
                      description="Select status to filter"
                      options={[
                        { value: 'enable', label: 'Enable' },
                        { value: 'disable', label: 'Disable' }
                      ]}
                      value={value.filter_status}
                      onOk={handleChange('filter_status')}>
                      <StyledDictionaryIcon
                        className={classNames('filter', { active })}>
                        <CaretDown />
                      </StyledDictionaryIcon>
                    </DropdownCheckbox>
                  </StyledDictionaryCell>
                </th>
              )
            }
            return <th {...restProps}>{children}</th>
          }
        }
      }}
      title={() => {
        return (
          <StyledDictionaryRow>
            <StyledDictionaryCol span={10}>
              <StyledDictionaryTabs
                activeKey={value.dictionary_type}
                items={dictionaryTabsItems}
                onChange={handleChange('dictionary_type')}
                className="table_tabs"
              />
            </StyledDictionaryCol>
            <StyledDictionaryCol span={8} offset={6}>
              <StyledDictionarySeachContainer className={value.dictionary_type}>
                <InputSearch
                  allowClear
                  size="small"
                  placeholder="Search by name"
                  className="search"
                  onSearch={handleChange('search')}
                />
                <StyledDictionaryDropdown
                  menu={{
                    items: [
                      { key: DICTIONARY_CATEGORY.EVENT, label: 'Event' },
                      {
                        key: DICTIONARY_CATEGORY.ATTRIBUTE,
                        label: 'Attributes'
                      },
                      { key: DICTIONARY_CATEGORY.MOBILE, label: 'Mobile' }
                    ],
                    onClick: onClickAdd
                  }}>
                  <Button
                    size="small"
                    type="primary"
                    icon={<Icon component={Plus} />}
                    className="add">
                    Add field
                  </Button>
                </StyledDictionaryDropdown>
              </StyledDictionarySeachContainer>
            </StyledDictionaryCol>
          </StyledDictionaryRow>
        )
      }}
    />
  )
}
