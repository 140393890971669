import Icon from '@ant-design/icons'
import Select, {
  BaseOptionType,
  DefaultOptionType,
  SelectProps
} from 'antd/lib/select'
import cn from 'classnames'
import { Info } from 'icons/V2'
import { Ref, forwardRef, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { cssScrollBar } from 'styles'
import { filterOption, getLabelWithSearch } from 'utils'

const StyledFormListConditionSelect = styled.div`
  .ant-select {
    width: 100%;
  }
`

const StyledFormListConditionDropdown = styled.div`
  display: flex;
  align-items: stretch;

  .formListConditionSelect--dropdown-detail {
    display: none;
    min-width: 428px;
    max-height: 100%;
    padding: 1rem 0.75rem;
    border-left: 1px solid ${({ theme }) => theme?.colors?.neutral100};
    ${cssScrollBar}
  }
  .formListConditionSelect--dropdown-content {
    min-width: 100%;
    &.showDescription {
      min-width: 320px;
      .rc-virtual-list-holder {
        .rc-virtual-list-holder-inner {
          .ant-select-item {
            padding: 0.75rem 1rem;
            &.ant-select-item-group {
              position: relative;
              color: black;
              font-size: 0.75rem;
              font-weight: 600;
              padding: 0.75rem 1rem 0;
              padding-top: 1.25rem;
              &:before {
                content: '';
                display: block;
                width: 100%;
                heigth: 1px;
                border-top: 1px solid ${({ theme }) => theme?.colors?.gray50};
                position: absolute;
                left: 0;
                top: 0.5rem;
              }
              &:first-child {
                margin-top: 0;
                padding-top: 0.75rem;
                border-top: 0;
                &:before {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
    .rc-virtual-list-scrollbar {
      width: 6px !important;
    }

    .searchString {
      color: ${({ theme }) => theme?.colors?.blue600};
    }
    &:hover {
      + .formListConditionSelect--dropdown-detail {
        display: block;
      }
    }
  }
`

export type FormListConditionSelectRef = HTMLDivElement
export type FormListConditionSelectProps = Pick<
  SelectProps,
  | 'loading'
  | 'disabled'
  | 'allowClear'
  | 'size'
  | 'placeholder'
  | 'options'
  | 'defaultValue'
  | 'value'
  | 'onChange'
  | 'className'
  | 'style'
> & {
  showDescription?: boolean
}

const FormListConditionSelectRenderFunc = (
  props: FormListConditionSelectProps,
  ref?: Ref<FormListConditionSelectRef>
) => {
  const {
    loading,
    disabled,
    allowClear,
    size,
    placeholder = 'Search field',
    defaultValue,
    value,
    onChange,
    className,
    style,
    options: optionsProps,
    showDescription = false
  } = props
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState<string>()
  const [hoverOption, setHoverOption] = useState<DefaultOptionType>()

  const options = useMemo(() => {
    const options: Record<string, DefaultOptionType> = {}
    const optsProps = optionsProps || []
    for (const option of optsProps) {
      if (option.options) {
        option.options.forEach((opt: DefaultOptionType) => {
          if (typeof opt.value === 'string') {
            options[opt.value] = opt
          }
        })
        continue
      }
      if (typeof option.value === 'string') {
        options[option.value] = option
      }
    }
    return options
  }, [optionsProps])

  const optionsSearch = useMemo(() => {
    const _optionsProps = optionsProps || []
    const options: BaseOptionType[] = []
    for (const option of _optionsProps) {
      if (search) {
        if (option.options && option.options?.length) {
          const optionsChildren = option.options.filter(
            (option: BaseOptionType) =>
              filterOption(search, {
                label:
                  typeof option?.data === 'string'
                    ? `${option?.data} ${option.label}`
                    : option.label,
                value: option.value
              })
          )
          if (optionsChildren.length) {
            options.push({ ...option, options: optionsChildren })
          }
          continue
        }

        if (
          typeof option.label === 'string' &&
          filterOption(search, {
            label:
              typeof option?.data === 'string'
                ? `${option?.data} ${option.label}`
                : option.label,
            value: option.value
          })
        ) {
          options.push(option)
          continue
        }
      } else {
        options.push(option)
      }
    }

    return options
  }, [optionsProps, search])

  const onMouseOver = (field: string) => {
    return () => {
      setHoverOption(options?.[field])
    }
  }

  useEffect(() => {
    return () => {
      if (!open) {
        setSearch(undefined)
        setHoverOption(undefined)
      }
    }
  }, [open])

  return (
    <StyledFormListConditionSelect
      ref={ref}
      className={className}
      style={style}>
      <Select
        open={open}
        listItemHeight={7}
        onDropdownVisibleChange={setOpen}
        loading={loading}
        disabled={loading || disabled}
        size={size}
        allowClear={allowClear}
        placeholder={placeholder}
        showSearch
        searchValue={search}
        onSearch={setSearch}
        filterOption={false}
        optionFilterProp="searchString"
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        popupClassName={cn('!w-auto rounded-2xl', { 'p-0': showDescription })}
        dropdownRender={(origin) => {
          return (
            <StyledFormListConditionDropdown className="formListConditionSelect--dropdown">
              <div
                className={cn('formListConditionSelect--dropdown-content', {
                  showDescription
                })}>
                {origin}
              </div>
              {showDescription && (
                <div className="formListConditionSelect--dropdown-detail">
                  <div className="formListConditionSelect--description-container flex items-start">
                    <Icon
                      component={Info}
                      className="formListConditionSelect--description-icon mr-1 text-base leading-none"
                    />
                    <div className="formListConditionSelect--description-wrapper mt-[0.5px]">
                      <p className="formListConditionSelect--description-header mb-1 text-xs text-gray600">
                        Description
                      </p>
                      <div className="formListConditionSelect--description-content text-sm text-gray800">
                        {hoverOption?.description}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </StyledFormListConditionDropdown>
          )
        }}>
        {optionsSearch?.map((opt, idx) => {
          if (opt.options) {
            return (
              <Select.OptGroup key={`${opt.label}`}>
                {opt.options.map((option: DefaultOptionType, index: number) => {
                  return (
                    <Select.Option
                      key={`${option.value}-${index}`}
                      value={option.value}
                      onMouseOver={onMouseOver(option.value as string)}
                      disabled={option.disabled}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: `${getLabelWithSearch(
                            search || '',
                            typeof option.label === 'string' ? option.label : ''
                          )}`
                        }}
                      />
                    </Select.Option>
                  )
                })}
              </Select.OptGroup>
            )
          }

          return (
            <Select.Option
              key={`${opt.value}-${idx}`}
              value={opt.value}
              onMouseOver={onMouseOver(opt.value as string)}
              disabled={opt.disabled}>
              <span
                dangerouslySetInnerHTML={{
                  __html: `${getLabelWithSearch(
                    search || '',
                    typeof opt.label === 'string' ? opt.label : ''
                  )}`
                }}
              />
            </Select.Option>
          )
        }, [])}
      </Select>
    </StyledFormListConditionSelect>
  )
}
export const FormListConditionSelect = forwardRef(
  FormListConditionSelectRenderFunc
)
