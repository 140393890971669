import { DICTIONARY_CATEGORY } from '../DictionaryTable/DictionaryTable.helpers'
import { Rule } from 'antd/lib/form'
import { FieldItemUpdate } from 'interfaces/field'

export type DictionaryFormValue = Omit<FieldItemUpdate, 'status'> & {
  status?: boolean
}

export const dictionaryFormSkeleton = {
  formItem: {
    paragraph: { rows: 1, width: '100%' },
    active: true,
    className: 'form_item'
  }
}

export const dictionaryFormRules: Partial<
  Record<keyof DictionaryFormValue, Rule[]>
> = {
  name: [
    {
      required: true,
      message: 'Please input field name'
    },
    {
      whitespace: true,
      message: 'Please input field name with characters'
    },
    {
      max: 255,
      message: 'Maximum 255 characters for name'
    }
  ],
  field: [
    {
      required: true,
      message: 'Please input key'
    },
    {
      whitespace: true,
      message: 'Please input field name with characters'
    },
    {
      async validator(rule, value, callback) {
        if (value.includes(' ')) {
          return Promise.reject('Please input field name with characters')
        }

        return Promise.resolve()
      }
    }
  ],
  event_ids: [
    {
      required: true,
      message: 'Please choose event'
    }
  ],
  type: [
    {
      required: true,
      message: 'Please choose data type'
    }
  ]
}

export function formatDictionaryFormValue(input: any): DictionaryFormValue {
  const newValue: DictionaryFormValue = {
    id: input?.id,
    category: input?.category || DICTIONARY_CATEGORY.EVENT,
    description: input?.description,
    field: input?.key || input?.field || '',
    name: input?.name || '',
    type: input?.type || '',
    event_ids: [],
    category_id: input?.category_id,
    status: Boolean(input?.status === 'enable')
  }

  if (Array.isArray(input?.events)) {
    newValue.event_ids = input.events
      ?.map((event: any) => (event?.id ? event.id : undefined))
      .filter(Boolean)
  }

  return newValue
}
