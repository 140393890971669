export const ENV = {
  nodeEnv: process.env.NODE_ENV as 'production' | 'development',
  reactEnv: process.env.REACT_APP_ENV as 'staging' | 'production',
  baseApi: process.env.REACT_APP_BASE_API as string,
  baseOlympusApi: process.env.REACT_APP_BASE_OLYMPUS_API as string,
  baseOlympusClientId: process.env.REACT_APP_OLYMPUS_CLIENT_ID as string,
  baseSidisGetUrl: process.env.REACT_APP_BASE_SIDIS_GET_API as string,
  baseSidisUpdateUrl: process.env.REACT_APP_BASE_SIDIS_UPDATE_API as string,
  baseSegmentApi: process.env.REACT_APP_BASE_SEGMENT_API as string,
  baseCopilotApi: process.env.REACT_APP_BASE_COPILOT_API as string,
  baseSegmentBuilderApi: process.env
    .REACT_APP_BASE_SEGMENT_BUILDER_API as string,
  baseLeadApi: process.env.REACT_APP_BASE_LEAD_API as string,
  baseTemplateApi: process.env.REACT_APP_BASE_SIDIS_TEMPLATE_API as string
}

export const isProduction = ENV.reactEnv === 'production'

export const DISABLED_SMS = isProduction
