import Icon from '@ant-design/icons'
import cn from 'classnames'
import { MainHeader, menuRoutes } from 'components/molecules'
import { theme } from 'constants/theme'
import { FacebookImage } from 'icons/V2'
import { useNavigate } from 'react-router'

export const INTEGRATION_ITEM_KEYS = {
  facebook_conversions_api: 'facebook_conversions_api'
}

const INTEGRATION_ITEMS = {
  [INTEGRATION_ITEM_KEYS.facebook_conversions_api]: {
    icon: FacebookImage,
    label: 'Facebook Conversions API',
    description:
      'Sidis lets you connect Facebook Conversions with thousands of the most popular apps, so you can automate your work and have more time for what matters most—no code required.'
  }
}

export function IntegrationPage() {
  const navigate = useNavigate()
  return (
    <MainHeader
      headerTitle="Integration"
      bgColor={theme.colors.neutral50}
      stickyHeader>
      <div className="w-full max-w-[1152px] mx-auto p-6">
        <div className="bg-white p-6 rounded-2xl border border-solid border-neutral100">
          <h1 className="mb-2 text-base font-semibold">Integration items</h1>
          <div className="integration--list flex flex-wrap -m-2">
            {Object.keys(INTEGRATION_ITEMS).map((k) => {
              const key = k as keyof typeof INTEGRATION_ITEMS
              const item = INTEGRATION_ITEMS[key]

              return (
                <div className="p-2 w-1/2" key={`${key}`}>
                  <div
                    className={cn(
                      'integration--item',
                      'p-4 border border-solid border-neutral100 rounded-2xl cursor-pointer'
                    )}
                    onClick={() =>
                      navigate(`${menuRoutes.integration}/${key}`)
                    }>
                    {item?.icon && (
                      <Icon
                        component={item.icon}
                        className="text-[28px] leading-[0] mb-4"
                      />
                    )}
                    <h3 className="mb-2 text-base font-semibold">
                      {item.label}
                    </h3>
                    <p className="m-0 text-sm text-gray600">
                      {item.description}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </MainHeader>
  )
}
