import {
  DICTIONARY_CATEGORY,
  dictionaryOptionsDataType
} from '../DictionaryTable/DictionaryTable.helpers'
import {
  DictionaryFormValue,
  dictionaryFormRules,
  dictionaryFormSkeleton,
  formatDictionaryFormValue
} from './DictionaryForm.helpers'
import {
  StyledDictionaryForm,
  StyledSkeletonFormItem
} from './DictionaryForm.styled'
import { Form, FormInstance, FormProps, Input, Switch } from 'antd'
import { FormListConditionSelect } from 'components/atoms/FormListCondition/FormListConditionSelect'
import { Select, SelectEvent } from 'components/atoms/select'
import { useFieldCategory } from 'hooks/useFieldCategory'
import { FieldCategoryItem } from 'interfaces/field'
import {
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from 'react'
import { filterOption } from 'utils'

export type { DictionaryFormValue }
export { formatDictionaryFormValue }
export type DictionaryFormRef = FormInstance<DictionaryFormValue>
export type DictionaryFormProps = Pick<
  FormProps<DictionaryFormValue>,
  | 'disabled'
  | 'initialValues'
  | 'onValuesChange'
  | 'onFinish'
  | 'onFinishFailed'
> & {
  type: string
  loading?: boolean
}

const DictionaryFormWithoutRef = (
  props: DictionaryFormProps,
  ref?: Ref<DictionaryFormRef>
) => {
  const {
    type,
    loading,
    disabled,
    initialValues,
    onValuesChange,
    onFinish,
    onFinishFailed
  } = props
  const [form] = Form.useForm()
  const { loading: loadingFieldCategory, fetchFieldCategory } =
    useFieldCategory()
  const [fieldCategories, setFieldCategories] = useState<
    FieldCategoryItem[] | null
  >(null)

  const dataTypeOptions = useMemo(() => {
    return dictionaryOptionsDataType[type]
  }, [type])
  const fieldCategoryOptions = useMemo(() => {
    return fieldCategories?.map(({ id, name }) => ({ value: id, label: name }))
  }, [fieldCategories])

  useEffect(() => {
    form.setFieldsValue({ ...initialValues, category: type })
  }, [initialValues, type])

  useEffect(() => {
    fetchFieldCategory({ filter_type: [type], filter_status: [true] }).then(
      ({ data }) => setFieldCategories(data)
    )
  }, [fetchFieldCategory, type])

  useImperativeHandle(ref, () => form, [form])

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{ ...initialValues }}
      onValuesChange={onValuesChange}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}>
      <StyledDictionaryForm>
        <Form.Item name="category" hidden>
          <Input />
        </Form.Item>
        <StyledSkeletonFormItem
          loading={loading}
          {...dictionaryFormSkeleton.formItem}>
          <Form.Item
            name="name"
            label="Field name"
            rules={dictionaryFormRules.name}
            required>
            <Input placeholder="Field name" />
          </Form.Item>
        </StyledSkeletonFormItem>
        <StyledSkeletonFormItem
          loading={loading}
          {...dictionaryFormSkeleton.formItem}>
          <Form.Item
            name="field"
            label="Key"
            rules={dictionaryFormRules.field}
            required>
            <Input placeholder="Key" disabled={disabled} />
          </Form.Item>
        </StyledSkeletonFormItem>
        <Form.Item shouldUpdate noStyle>
          {type === DICTIONARY_CATEGORY.EVENT && (
            <StyledSkeletonFormItem
              loading={loading}
              {...dictionaryFormSkeleton.formItem}>
              <Form.Item
                name="event_ids"
                label="Event"
                rules={dictionaryFormRules.event_ids}
                required>
                <SelectEvent
                  showSearch
                  filterOption={filterOption as any}
                  placeholder="Event"
                  mode="multiple"
                  maxTagCount={5}
                  disabled={disabled}
                />
              </Form.Item>
            </StyledSkeletonFormItem>
          )}
        </Form.Item>
        <StyledSkeletonFormItem
          loading={loading}
          {...dictionaryFormSkeleton.formItem}>
          <Form.Item
            name="type"
            label="Data type"
            rules={dictionaryFormRules.type}
            required>
            <Select
              options={dataTypeOptions}
              placeholder="Data type"
              disabled={disabled}
            />
          </Form.Item>
        </StyledSkeletonFormItem>
        <StyledSkeletonFormItem
          loading={loading}
          {...dictionaryFormSkeleton.formItem}>
          <Form.Item name="description" label="Description">
            <Input.TextArea placeholder="Description" rows={2} />
          </Form.Item>
        </StyledSkeletonFormItem>
        <StyledSkeletonFormItem
          loading={loading}
          {...dictionaryFormSkeleton.formItem}>
          <Form.Item name="category_id" label="Category">
            <FormListConditionSelect
              loading={loadingFieldCategory}
              options={fieldCategoryOptions}
            />
          </Form.Item>
        </StyledSkeletonFormItem>
        <StyledSkeletonFormItem
          loading={loading}
          {...dictionaryFormSkeleton.formItem}>
          <Form.Item name="status" label="Status" valuePropName="checked">
            <Switch size="small" />
          </Form.Item>
        </StyledSkeletonFormItem>
      </StyledDictionaryForm>
    </Form>
  )
}
export const DictionaryForm = forwardRef(DictionaryFormWithoutRef)
